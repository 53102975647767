@tailwind base;
@tailwind components;
@tailwind utilities;

.fa.fa-shopping-cart {
  color: #fff;
  font-size: 19px;
}
.required:after {
  content: " *";
  color: red;
}
body {
  font-family: Roboto, sans-serif;
}
/*Editor code start*/
.SearchResult-body h1 {
  font-size: 36px;
  font-weight: bold;
}

.SearchResult-body h2 {
  font-size: 32px;
  font-weight: bold;
}

.SearchResult-body h3 {
  font-size: 24px;
  font-weight: bold;
}
.SearchResult-body ul {
  list-style-type: square;
  list-style-position: outside;
  list-style-image: none;
}
/*Editor code end*/

/* blog css start here */

.blog {
  font-weight: bold;
}

.bg-grey {
  background-color: rgba(159, 159, 159, 0.1) !important;
}

.blog-box h2,
.blog-inner-box h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.blog-inner-box h5.date {
  color: #294378;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.blog-box h5.date {
  color: #294378;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.blog-box a {
  text-transform: uppercase;
  margin: 20px 0 0;
  display: block;
  font-weight: bold;
  text-decoration: underline;
}

.blog h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin: 1rem 0;
}

.blog-box {
  padding: 2rem;
  background: #fff;
  margin: 1rem;
  border: 1px solid #ededed;
  height: 85%;
}

.blog-inner-box {
  padding: 2rem;
  background: #fff;
  margin: 1rem;
  border: 1px solid #ededed;
}

.blog-box h5.date span,
.blog-inner-box h5.date span {
  color: #000;
  font-weight: normal;
}

.click-italic h4 a {
  display: inline-block;
  margin: 0;
}

.click-italic h4 {
  font-weight: normal;
  font-style: italic;
  text-transform: capitalize;
}

.click-italic {
  margin-bottom: 15px;
}

.blog-box p,
.blog-inner-box p {
  margin-bottom: 15px;
}

.blog-box ul li,
.blog-inner-box ul li {
  list-style: disc;
  margin: 0 0 5px 0;
}

.blog-box ul,
.blog-inner-box ul {
  margin: 0 0px 15px 20px;
  display: block;
}

.blog-inner-box p a {
  text-decoration: underline;
  color: #294378;
}

.blog-inner-box h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.blog-inner-box h2 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 15px;
}

div.block-section {
  display: inline-block;
  font-size: 20px;
  padding: 20px;
}

/* blog css end here */

/* 20/7/2022 css start here */
.navbar-tabs {
  justify-content: space-between !important;
}
/* 20/7/2022 css start here */

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.95;
  color: #000;
}
.slick-prev {
  left: 10px;
}

.flex-custom {
  display: flex;
  width: 100%;
}

.left-content {
  width: 60%;
}

.right-image {
  width: 40%;
  position: relative;
  top: 0px;
}
.inner-flex {
  display: flex;
  width: 100%;
}

.inner-flex .left-content {
  width: 75%;
}

.inner-flex .right-image {
  width: 25%;
  position: relative;
  top: 0px;
}
.right-image img {
  min-height: 390px;
  position: absolute;
}

.border-style {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, #437def, rgba(255, 255, 255, 0)) 1;
}

.custom-table tbody tr:nth-child(even) td {
  background: #f5f5f5;
}

.custom-table tbody tr:nth-child(odd) td {
  background: #ebebeb;
}

.property-slider .owl-nav {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 30%;
}

.property-slider .owl-nav button span {
  font-size: 70px;
  background: 0 0;
}

.property-slider .owl-nav button.owl-prev {
  left: -40px;
  position: absolute;
}

.property-slider .owl-nav button.owl-next {
  right: -40px;
  position: absolute;
}

.property-slider .owl-nav button {
  background: 0 0 !important;
}

.property-slider .owl-nav button:hover span {
  color: #000;
}

.property-slider .owl-dots {
  margin-top: 30px;
}

.bottom-content {
  height: 800px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 40px;
  position: relative;
}

.custom-position {
  top: 50px;
}

.custom-font li a i {
  font-size: 2rem;
}

.custom-font li {
  margin: 0 15px;
}

div#Monile-menu {
  z-index: 99;
}
.overlay-bg {
  background: rgb(167 166 165 / 62%);
}
.bg-gray-1000 {
  --tw-bg-opacity: 1;
  background-color: #b8b8b8;
}
.h-132 {
  min-height: 390px;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.custom-spacing {
  border-spacing: 10px 0px;
}

/* home left bob for list*/
.listing-left-box {
  display: flex;
  justify-content: center;
}
.listing-left-box h2 {
  align-items: center;
  display: flex;
  justify-content: center;
}

/*25-07-2022*/
.inline-flex-custom {
  align-items: center;
  min-height: 400px;
}
.inline-flex-custom .right-image {
  top: 0;
}
.inline-flex-custom .right-image img {
  position: relative;
  height: 400px;
  min-height: 200px;
}
#exampleModal {
  display: none;
}
.team-content h2 {
  font-size: 24px;
  font-weight: bold;
  color: #1e40af;
  margin-top: 20px;
}
.team-content p {
  margin-bottom: 20px;
  font-size: 14px;
}
.w-modal {
  width: 1000px;
  max-width: 90%;
}
.openpupbody {
  max-height: 100vh;
  overflow: hidden;
}

/*25-07-2022 END*/

/*28-07-2022*/
.border-bottomheading {
  border-bottom: 1px solid #ccc;
}
.job-itemborder:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.headingjob {
  background-color: #1946ba;
  color: #fff;
}
.welooking a {
  color: #1946ba;
}

/*28-07-2022*/

/*Home */
.flex-custom {
  display: flex;
  width: 100%;
}

.left-content {
  width: 60%;
}

.right-image {
  width: 40%;
  position: relative;
  top: 0px;
}
.border-style {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, #437def, white) 1;
}
.custom-table tbody tr:nth-child(even) td {
  background: #f5f5f5;
}

.custom-table tbody tr:nth-child(odd) td {
  background: #ebebeb;
}
.property-slider .owl-nav {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 30%;
}

.property-slider .owl-nav button span {
  font-size: 70px;
  background: none;
}

.property-slider .owl-nav button.owl-prev {
  left: -40px;
  position: absolute;
}

.property-slider .owl-nav button.owl-next {
  right: -40px;
  position: absolute;
}

.property-slider .owl-nav button {
  background: none !important;
}

.property-slider .owl-nav button:hover span {
  color: #000;
}
.property-slider .owl-dots {
  margin-top: 30px;
}
.bottom-content {
  height: 800px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 40px;
  position: relative;
}
.custom-position {
  top: 50px;
}
.custom-font li a i {
  font-size: 2rem;
}
.custom-font li {
  margin: 0px 15px;
}
div#Monile-menu {
  z-index: 99;
}
.goog-te-gadget-simple {
  width: 100%;
  min-width: 110px;
  display: flex;
  justify-content: space-between;
}

.goog-te-gadget-simple span a {
  width: 100%;
  white-space: nowrap;
  display: flex;
}
.Menu-translator {
  position: relative;
  z-index: 999;
}
.property-sldier .images {
  /* height: 170px; */
  position: relative;
}
.property-sldier .images img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.property-slider .slick-slide .item {
  margin: 10px;
}
.property-slider .slick-slide .item .location {
  min-height: 90px;
}
.team-content1 button {
  max-width: 200px;
  margin-top: 10%;
  margin-bottom: 10px;
}
/* .slider-new {
  height: 94vh;
  overflow: hidden;
} */
.slick-slide,
.slider-new {
  height: 100vh;
  object-fit: cover;
}
/* .slick-slide {
  height: 94vh;
  overflow: hidden;
} */

.slick-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 1535px) {
  .property-slider .owl-nav {
    display: none;
  }
  .right-image img {
    min-height: 390px;
    position: absolute;
  }
  .centered-div {
    padding: 130px 0px;
  }
  .h-132 {
    min-height: 390px;
  }
}

.left-side-custom {
  border-right-color: rgba(211, 220, 228, 1);
  width: 100%;
  max-width: 260px;
  margin-left: auto;
  height: 100vh;
}
.mar-left-auto {
  padding: 10px 15px;
  margin-top: 15px;
}
ul.ul-left-side {
  margin-top: 2rem;
  overflow: auto;
  height: 80vh;
}
ul.ul-left-side li a {
  padding: 10px 15px;
  display: block;
}

ul.ul-left-side li a {
  padding: 10px 15px;
  display: block;
  color: #767676;
  font-size: 1rem;
  letter-spacing: 0.5px;
}
.language-sec {
  padding: 10px 15px;
  display: flex;
  align-items: center;
}
.language-sec span {
  margin-left: 10px;
  font-size: 1rem;
  color: #767676;
}
.bg-grey-cs {
  background-color: #f5f7f9;
}
.inner-content {
  margin-left: 80px;
}
.inner-content h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 10px;
}
.main-tab-content {
  padding: 2rem 0px;
}
.content-inner {
  margin-top: 20px;
}
h5 {
  font-size: 18px;
  font-weight: 500;
}
ul.ul-inner li {
  list-style: disc;
  margin-bottom: 10px;
}
ul.ul-inner {
  padding-left: 15px;
}
.main-ul {
  margin-top: 18px;
}

ul.ul-inner li {
  list-style: disc;
  margin-bottom: 10px;
}
h4 {
  font-size: 26px;
  font-weight: 700;
}
ul.ul-left-side li a.active {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-right: 0;
  color: #e3970d;
}

/*for Cart CSS 23-09-2022;*/

table.w-100 {
  width: 100%;
}

.cart-table .inner-table tr th.first-child,
.cart-table .inner-table tr td.first-child {
  width: 70%;
  text-align: left;
  padding-left: 4rem;
}
table.inner-table tbody tr td:first-child {
  width: 70%;
}
.cart-table .inner-table thead tr th,
.cart-table .inner-table tbody tr td {
  padding: 10px 15px;
  text-align: left;
}

.btn-cart a.continue-shop {
  color: #294378;
  border: 3px solid #294378;
  padding: 5px 20px;
  border-radius: 23px;
  font-size: 18px;
  font-weight: 600;
}

.btn-cart button.continue-shop {
  color: #294378;
  border: 3px solid #294378;
  padding: 5px 20px;
  border-radius: 23px;
  font-size: 18px;
  font-weight: 600;
}

input.quantity-cell {
  width: 100%;
  max-width: 100px;
  height: 46px;
  background-color: #cdcdcd;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  padding: 0px 15px;
}
.image-sec-cart {
  display: flex;
}
.btn-cart {
  margin-bottom: 4rem;
}
.box-shadow-table {
  box-shadow: 2px 2px 4px #d9d9d9;
  margin-bottom: 1rem;
}

.inner-table tr th,
.inner-table tr td {
  width: 12%;
}
.text-right {
  text-align: right;
}
.cart-total-sec {
  width: 100%;
  max-width: 25%;
  margin-left: auto;
}
.cart-tot h2 {
  font-size: 1.8rem;
  font-family: "Roboto";
  font-weight: 600;
}
.cart-tot {
  margin-bottom: 1rem;
}
.total-section {
  display: flex;
  justify-content: space-between;
}
.total-section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a4a4a;
}
.total-section p {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
}
.mrt-2 {
  margin-top: 1.2rem;
}
.w-1000 {
  width: 100%;
}
.gift-section {
  box-shadow: 1px 5px 10px #b8b8b8;
  padding: 20px;
}
.gift-section a {
  text-align: center;
  display: block;
  color: #0078ff;
  font-weight: 500;
}
.gift-section span {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
/*Checkout */

.border-bottom-cus {
  border-bottom: 1px solid #000;
}
th.total-font {
  font-size: 20px;
}
.form-check {
  padding: 0rem 1rem;
}
.bg-box {
  background-color: #f1f1f1;
  border-radius: 10px;
}
.bg-box {
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 1px 4px 6px #9c9c9c;
  padding: 3rem 1rem;
}
.img-icon img.img-icon-img {
  width: 100%;
  max-width: 35px;
  margin: 0px 5px;
}
.img-icon {
  height: fit-content;
}
.main-wrapper.bg-box .form-check input[type="radio"] {
  margin-right: 10px;
}
.bg-wrapper-form {
  background-color: #156cab;
  position: relative;
}
.bg-wrapper-form h5 {
  color: #fff;
  font-size: 17px;
}
.bg-wrapper-form label {
  color: #fff;
}
.bg-wrapper-form:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 13px solid #156cab;
  position: absolute;
  top: -13px;
}
span.hr-custom {
  display: block;
  border-bottom: 1px solid #000;
  position: relative;
}
.bg-blue-max {
  background-color: #112c4c;
  padding: 10px 20px;
  border-radius: 7px;
}
.bg-blue-max label {
  color: #fff;
  font-size: 16px;
}
a.color-blue {
  color: #013f6d;
}

/*Checkout */
@media only screen and (max-width: 1149px) {
  .flex-custom {
    flex-direction: column;
  }

  .left-content {
    width: 100%;
    order: 2;
  }

  .right-image {
    order: 1;
    top: 40px;
    margin: 0 auto;
  }

  .custom-position {
    top: 0;
    left: -70px;
  }
  .inner-flex .right-image {
    order: 2;
  }
  .right-image img {
    min-height: 415px;
    position: static;
    width: 100%;
  }
  .h-132 {
    min-height: 385px;
  }
}
@media only screen and (max-width: 1023px) {
  .custom-position {
    top: 0;
    left: 0;
  }

  .bottom-content {
    height: auto;
    background: 0 0 !important;
  }
  .inner-flex {
    flex-wrap: wrap;
    padding: 20px 0px;
  }

  .inner-flex .right-image {
    width: 100%;
    max-width: 320px;
    display: none;
  }

  .inner-flex .left-content {
    width: 100%;
    height: 100%;
  }
  .h-132 {
    min-height: auto;
  }
  .custom-font li a i {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .right-image {
    margin: 40px auto;
    top: 0;
  }
  .right-image img {
    min-height: auto;
    position: static;
    width: 100%;
  }
  .slick-prev {
    left: 7px;
  }
  .slick-next {
    right: 14px;
  }
  .slick-next,
  .slick-prev {
    top: 50%;
    width: 20px;
    height: 20px;
    z-index: 9;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 25px;
  }
  .Menu-translator {
    position: absolute;
    z-index: 999;
    bottom: -25px;
    left: auto;
    right: 0;
    width: 160px;
    float: right;
  }

  .slider-new {
    height: Auto;
  }

  .slick-slide {
    height: Auto;
  }
}
/*preeti*/
.copy-right p a,
p.term_pra a {
  color: #3747aa;
}
p.term_pra a:hover {
  color: #576cf5;
}
.copy-right {
  border-top: 5px solid #ddd;
}
.team-image img {
  width: 100%;
  height: 200px;
}
.slick-slide img {
  margin: 0 auto;
}
.slick-next {
  right: 15px;
}
.submenu-dropdown {
  position: relative;
}
.submenu-ul {
  position: absolute;
  background-color: white;
  min-width: 150px;
  padding: 0px;
  box-shadow: 0px 0px 7px #00000024;
  display: none;
}
.submenu-dropdown:hover .submenu-ul {
  display: block;
}

.submenu-ul li a:hover {
  background-color: black;
  color: #ffffff;
}
.submenu-dropdown:hover .fa {
  transform: rotate(-180deg);
}
.text-red {
  color: red;
}
.team-image {
  height: 470px;
  overflow: hidden;
}

.team-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

span.quantity-val {
  position: absolute;
  top: -15px;
  z-index: 9;
  font-size: 13px;
  left: 19px;
  background-color: #fff;
  width: 20px;
  text-align: center;
  border-radius: 19px;
  color: #000;
  font-weight: 600;
}

img.img-icons {
  width: 30px;
}
.para-cus {
  font-size: 13px;
  color: red;
}

/* 05-10-2022 */

.left-side-custom {
  border-right-color: rgba(211, 220, 228, 1);
  width: 100%;
  max-width: 260px;
  margin-left: auto;
  height: 100vh;
}

.mar-left-auto {
  padding: 10px 15px;
  margin-top: 15px;
}

ul.ul-left-side {
  margin-top: 2rem;
  overflow: auto;
  height: 80vh;
}

ul.ul-left-side li a {
  padding: 10px 15px;
  display: block;
}

ul.ul-left-side li a {
  padding: 10px 15px;
  display: block;
  color: #767676;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.language-sec {
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

.language-sec span {
  margin-left: 10px;
  font-size: 1rem;
  color: #767676;
}

.bg-grey-cs {
  background-color: #f5f7f9;
}

.inner-content {
  margin-left: 80px;
}

.inner-content h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 10px;
}

.main-tab-content {
  padding: 2rem 0px;
}

.content-inner {
  margin-top: 20px;
}

h5 {
  font-size: 18px;
  font-weight: 500;
}

ul.ul-inner li {
  list-style: disc;
  margin-bottom: 10px;
}

ul.ul-inner {
  padding-left: 15px;
}

.main-ul {
  margin-top: 18px;
}

ul.ul-inner li {
  list-style: disc;
  margin-bottom: 10px;
}

h4 {
  font-size: 26px;
  font-weight: 700;
}

ul.ul-left-side li a.active {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-right: 0;
  color: #e3970d;
}
/*FAQ*/
.inner-content ul {
  list-style: square;
}
.tox-notification {
  display: none !important;
}
.goog-te-gadget-simple {
  display: flex;
}

.card_heiht {
  min-height: 300px;
  height: 100%;
}
.datatable.sell-dist {
  height: auto;
  padding: 1px 0 0 0 0 !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 50vw !important;
  max-width: none !important;
  overflow-y: unset !important;
}
.MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
  border: 1px solid #ddd;
  padding: 10px !important;
  margin: 0 20px;
  border-radius: 4px;
  background: #dddddd2b;
}
p#alert-dialog-description {
  display: flex;
  width: 100%;
}
.formCustom {
  width: 50%;
  padding: 0 10px;
}
.formCustom input {
  width: 100%;
  border: 1px solid #989898;
}
.formCustom lable {
  font-size: 15px;
  font-weight: 600;
}
.btnReport {
  background: #0078ff;
  color: #fff;
  border-radius: 4px;
  padding: 5px 15px;
}
.btnCancel {
  background: rgb(253, 42, 42);
  color: #fff;
  border-radius: 4px;
  padding: 5px 15px;
  margin: 0 10px;
}
.moadl-footer {
  padding: 0 10px;
}

.flex-Wrap {
  flex-wrap: wrap;
}

.messageBOX {
  border: 1px solid #000;
  height: 100px;
  vertical-align: top;
  overflow-y: auto;
}

.squarePad canvas {
  width: 100%;
}

.slick-slide img {
  height: auto !important;
  width: 100% !important;
  object-fit: contain;
}
/* .slick-slide img {
  height: auto !important;
  width: 100% !important;
  object-fit: cover;
} */
.goog-te-gadget .goog-te-combo option {
  color: #fff !important;
  background: #1e3a8a !important;
}
.goog-te-gadget .goog-te-combo {
  white-space: nowrap;
  background: #1e3a8a;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  margin-right: 1.3rem;
}
button.ring-1.connectBtn,
select.ring-1.EthermBtn {
  border-radius: 5px !important;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  z-index: 9;
}
.slick-dots {
  display: none !important;
  bottom: -5px !important;
}
thead.bg-gray-500 {
  background: #1e3a8a !important;
}
.desktopUl_Menu li + button:hover,
.desktopUl_Menu li + button {
  background: #1e3a8a;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: none;
  font-size: 20px;
}

@media only screen and (max-width: 420px) {
  .desktopUl_Menu li + button:hover,
  .desktopUl_Menu li + button {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
  }
}

.total-font {
  font-weight: normal;
}

p.ml-20.text-sm.text-orange-500 {
  color: chocolate;
}

p.text-sm.text-orange-500 {
  color: chocolate;
}
.price-font {
  color: chocolate;
  font-weight: normal;
}
@media screen and (max-width: 375px) {
  .my-btn {
    margin: 0 auto;
    padding-top: 1rem;
  }
  .my-text {
    font-size: 24px;
  }
  .nav-toggle :hover #Monile-menu {
    display: block;
  }
}

.my-text {
  font-size: 32px !important;
}
.property-className .images img {
  max-height: 180px;
}
.property-className:has(div.images) {
  margin-top: 0;
}
.property-className:not(:has(div.images)) {
  margin-top: 180px;
}
@media screen and (max-width: 440px) {
  .my-btn {
    margin: 0 auto;
    padding-top: 1rem;
  }
  a.text-blue-900.text-md.underline.font-medium {
    word-break: break-all;
  }
  td.p-5.text-left {
    padding-left: 10px !important;
  }
  .card_heiht {
    width: 87vw;
  }
  h2.official-link {
    font-size: 14px !important;
  }
}
.flex.\32 xl\:flex-row.xl\:flex-row.lg\:flex-row.md\:flex-row {
  overflow: hidden;
}

.property-sldier .images {
  height: 180px;
  overflow: hidden;
}

.tableBox {
  width: 100%;
  overflow-x: auto;
}

.tableBox table {
  table-layout: auto;
}

.breakWord {
  word-break: break-word;
}

.centertext-cartpage {
  text-align: center;
}
.centertext-cartpage a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centertext-cartpage a img {
  width: 60px;
  margin-left: 20px;
}
.centertext-cartpage a p {
  font-size: 22px;
}
.metamask-wallet-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.metamask-wallet-text img {
  width: 60px;
  margin-left: 20px;
}
.metamask-wallet-text p {
  font-size: 22px;
}
.logo-section a {
  display: block;
  padding: 6px 0;
}
.header-section.is-sticky {
  min-height: 64px;
}
.header-section.is-sticky .headermains {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: 0.5s;
  height: 100px;
}
.header-section .headermains {
  position: sticky;
  top: -100px;
  z-index: 1;
}
.header-section.is-sticky .logo-section a {
  padding: 0;
}
.logo-section img {
  height: 6rem;
}
li a.text-base {
  font-size: 20px;
}
button.bg-black.text-white.font-semibold.py-2.px-4.rounded.inline-flex.items-center.text-xs {
  font-size: 20px;
}
a.rounded-t.hover\:text-gray-100.py-2.px-4.block.whitespace-no-wrap.text-gray-100 {
  font-size: 18px;
}
input.rent-checkbox {
  width: 20px;
  height: 20px;
}
.popBox {
  margin: 0 auto;
}
.popBox h3 {
  font-size: 24px;
  color: #000;
}

.btnBox {
  display: flex;
  align-items: center;
}

.radioBox {
  margin-left: 20px;
}
.radioBox span {
  font-size: 17px;
  color: #1e3a8a;
}
strong span {
  color: #c12929 !important;
}
.dialougBox {
  font-size: 20px;
  color: #000;
}
input[name="payoutBlockchainNetwork"] {
  width: 18px;
  height: 18px;
}

@media only screen and (min-width: 240px) and (max-width: 491px) {
  .first-shap img {
    max-width: 50%;
  }
  .header-section.is-sticky .headermains {
    height: 135px;
  }
}

@media only screen and (min-width: 492px) and (max-width: 667px) {
  .first-shap img {
    max-width: 100px;
  }
}
@media only screen and (min-width: 668px) and (max-width: 1015px) {
  .first-shap span {
    margin: 0;
  }
  .first-shap img {
    max-width: 100px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 991px) {
  .header-section.is-sticky .headermains {
    height: 170px;
  }
}
@media only screen and (min-width: 240px) and (max-width: 600px) {
  .header-section.is-sticky .headermains {
    height: 135px;
  }
}
@media only screen and (min-width: 240px) and (max-width: 1017px) {
  .image {
    min-height: 390px !important;
  }
}
a.view {
  white-space: nowrap;
}

.active {
  background: #dcd3e394;
  border-radius: 8px;
  width: 100%;
}

.social-login {
  height: 40px;
  background: white;
  width: 40px;
  border-radius: 50%;
  padding: 0px;
  text-align: center;
  margin: 0px 10px;
}
.social-login:hover {
  background: #e9d3d3;
}
